import React, { useState } from 'react';
// components
import Accordion from '../accordion';
import Image from '@components/image/image';

// styles
import styles from './ingredients.module.scss';

interface IProps {
  ingredients: any;
  displayTitle?: boolean;
}

const Ingredients: React.FC<IProps> = ({ ingredients, displayTitle = true }: IProps) => {
  const [activeIngredient, setActiveIngredient] = useState(ingredients[0]);

  const accordionItems = ingredients.map((ingredient: any) => {
    const getContent = () => (
      <>
        <div>
          <div dangerouslySetInnerHTML={{ __html: ingredient.description }} />
          {ingredient.amount && (
            <span className={styles.unit}>{`${ingredient.amount} ${ingredient?.unit?.short_name || ''}`}</span>
          )}
        </div>

        {ingredient.image && (
          <div className={styles.mobileingredientImage}>
            <Image filename={ingredient.image.filename_disk} width={358} alt={ingredient.image.title} />
          </div>
        )}
      </>
    );

    return {
      question: ingredient.name,
      answer: getContent(),
    };
  });

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        {displayTitle && (
          <div className={styles.row}>
            <div className={styles.ingredientsContent}>
              <h2>Ingredients</h2>
              <hr />
            </div>
          </div>
        )}

        <div className={styles.mobileRow}>
          <div className={styles.mobileIngredients}>
            <Accordion items={accordionItems} />
          </div>
        </div>

        <div className={styles.desktopRow}>
          <div className={styles.ingredientsButtons}>
            {ingredients.map((ingredient: any) => {
              return (
                <button
                  key={ingredient.name}
                  className={
                    ingredient.name === activeIngredient.name ? styles.activeIngredientButton : styles.ingredientButton
                  }
                  onClick={() => setActiveIngredient(ingredient)}
                >
                  {ingredient.name}
                </button>
              );
            })}
          </div>

          <div className={styles.ingredientContentWrapper}>
            <div className={styles.ingredientContent}>
              <h4>{activeIngredient.name}</h4>
              <hr className={styles.hrLeftAligned} />
              <div
                dangerouslySetInnerHTML={{
                  __html: activeIngredient.description,
                }}
              />
              {activeIngredient.amount && (
                <p className={styles.unit}>
                  {`${activeIngredient.amount}${activeIngredient?.unit?.short_name || ''}`}
                  {!!activeIngredient.nrv && (
                    <span className={styles.nrv}>
                      {' '}
                      (<strong>{`${activeIngredient.nrv}`}</strong>% NRV)
                    </span>
                  )}
                </p>
              )}
              {activeIngredient.image && (
                <img
                  width={480}
                  className={styles.image}
                  srcSet={`
                  ${process.env.GATSBY_MEDIA_URL}${activeIngredient.image.filename_disk}?w=480 1x,
                  ${process.env.GATSBY_MEDIA_URL}${activeIngredient.image.filename_disk}?w=960 2x
                  `}
                  src={`${process.env.GATSBY_MEDIA_URL}${activeIngredient.image.filename_disk}?w=480`}
                  alt={activeIngredient.image.title}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Ingredients;
