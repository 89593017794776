import React, { useState } from 'react';

// styles
import styles from './compare-panels.module.scss';

interface IProps {
  items: any;
}

const ComparePanels: React.FC<IProps> = ({ items }) => {
  const defaultSelectedCategory = items[0].id;
  const [selectedCategory, setSelectedCategory] = useState(defaultSelectedCategory);

  const getSelectedCategoryContent = () => {
    const category = items.find((item: any) => item.id === selectedCategory);
    return category;
  };

  const selectedCategoryContent = getSelectedCategoryContent();

  return (
    <div>
      <div className={styles.categories}>
        {items.map((item: any) => (
          <div
            key={item.id}
            className={selectedCategory === item.id ? styles.selectedCategory : styles.category}
            onClick={() => {
              setSelectedCategory(item.id);
            }}
          >
            {item.title}
          </div>
        ))}
      </div>

      <div id={selectedCategoryContent.id} className={styles.comparePanels}>
        <div className={styles.panel1}>
          <h5>Vitl</h5>
          <img src="/images/icon-compare-check.svg" width="16px" alt="check" />
          <p>{selectedCategoryContent.vitl_description}</p>
          {/* display icons only for the first panel */}
          {selectedCategory === items[0].id && (
            <div className={styles.icons}>
              <div>
                <img src="/images/icon-boxed-vegan.svg" width="82px" alt="Vegan" />
                Vegan
              </div>
              <div>
                <img src="/images/icon-boxed-highly-absorbable.svg" width="82px" alt="Highly absorbable" />
                Highly absorbable
              </div>
              <div>
                <img src="/images/icon-boxed-non-gmo.svg" width="82px" alt="Non-GMO" />
                Non-GMO
              </div>
            </div>
          )}
        </div>
        <div className={styles.panel2}>
          <h5>Others</h5>
          <img src="/images/icon-compare-cross.svg" width="16px" alt="cross" />
          <p>{selectedCategoryContent.others_description}</p>
        </div>
      </div>
    </div>
  );
};

export default ComparePanels;
