import React from 'react';
// interfaces
import { IImage } from '../../interfaces/image';
// components
import AddToBasket from '../add-to-basket';
import Image from '@components/image/image';
// styles
import styles from './hero-health-product.module.scss';
// constants
import { OUT_OF_STOCK } from '@constants/messages';

interface IProps {
  title: string;
  subtitle: string;
  description: string;
  image: IImage;
  backgroundColor: string;
  loadingPlans: boolean;
  plans: any;
  isWithDots?: boolean;
}

const HeroHealthProduct: React.FC<IProps> = ({
  title,
  subtitle,
  description,
  image,
  backgroundColor,
  loadingPlans,
  plans,
  isWithDots,
}: IProps) => {
  return (
    <section
      className={isWithDots ? styles.immuneHero : styles.hero}
      style={{
        backgroundColor,
      }}
    >
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={isWithDots ? styles.immuneImage : styles.image}>
            <Image filename={image.filename_disk} alt={image.title} width={1000} />
          </div>
          <div className={isWithDots ? styles.immuneContentWrapper : styles.contentWrapper}>
            <div className={styles.content}>
              <h1 className={styles.h1}>{title}</h1>
              <p>
                <strong>{subtitle}</strong>
              </p>
              <hr className={styles.hr} />
              <p>{description}</p>
              {!loadingPlans &&
                (plans && plans.length > 0 ? (
                  <AddToBasket plans={plans} />
                ) : (
                  <h4>{OUT_OF_STOCK}</h4>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroHealthProduct;
