import React, { FC } from 'react';
import { graphql } from 'gatsby';

import HealthGoalPageComponent from './health-goal-component';

export const data = graphql`
  query($path: String!) {
    directusProductTemplate1(path: { eq: $path }) {
      bg_with_dots
      page_title
      page_description
      hero_title
      hero_subtitle
      hero_description
      hero_image {
        filename_disk
        title
      }
      hero_background_color
      what_you_get_description
      what_you_get_items {
        title
        icon_filename_disk
        description
      }
      why_were_different_reasons {
        id
        title
        vitl_description
        others_description
      }
      single_monthly_image {
        filename_disk
        title
      }
      product {
        identifier
        pills {
          nutrients {
            name
            description
            image {
              filename_disk
              title
            }
            amount
            unit {
              short_name
            }
            nrv
          }
        }
      }
      more_health_goal_title
      more_health_goal_items {
        title
        filename_disk
        button_label
        path
        identifier
      }
    }
    directusProductTemplate1CommonElement {
      what_youll_get_title
      ingredients_title
      why_were_different_title
      recommended_products_title
    }
  }
`;
const HealthGoalPage: FC<any> = ({
  data: {
    directusProductTemplate1: {
      page_title,
      page_description,
      hero_title,
      hero_subtitle,
      hero_description,
      hero_image,
      hero_background_color,
      what_you_get_description,
      what_you_get_items,
      why_were_different_reasons,
      single_monthly_image,
      product,
      bg_with_dots,
      more_health_goal_title,
      more_health_goal_items,
    },
    directusProductTemplate1CommonElement: {
      add_to_basket_button_label,
      what_youll_get_title,
      ingredients_title,
      why_were_different_title,
      recommended_products_title,
    },
  },
}) => {
  const data = {
    page_title,
    page_description,
    hero_title,
    hero_subtitle,
    hero_description,
    hero_image,
    hero_background_color,
    what_you_get_description,
    what_you_get_items,
    why_were_different_reasons,
    single_monthly_image,
    product,
    bg_with_dots,
    more_health_goal_title,
    more_health_goal_items,
    add_to_basket_button_label,
    what_youll_get_title,
    ingredients_title,
    why_were_different_title,
    recommended_products_title,
  };
  return <HealthGoalPageComponent data={data} />;
};

export default HealthGoalPage;
