import React, { useEffect, useMemo, FC } from 'react';
import { useLocation } from '@reach/router';
// components
import Layout from '../components/layout';
import SEO from '../components/seo';
import HeroHealthProduct from '../components/hero-health-product';
import ComparePanels from '../components/compare-panels';
import AddToBasket from '../components/add-to-basket';
import RecommendedProducts from '../components/recommended-products';
import Ingredients from '../components/ingredients';
import Image from '@components/image/image';
import HowItWorks from '../components/how-it-works';
// services
import { trackProductViewed } from '../services/tracking/tracking';
// styles
import styles from './health-goal.module.scss';
// hooks
import useGetProductPlansData from '@src/features/shop/hooks/useGetProductPlansData';
// constants
import { OUT_OF_STOCK } from '@constants/messages';
import { IProductPlan } from '../types/product';

const getFilteredPlans = (plans: IProductPlan[]) => {
  const hasOffer = sessionStorage.getItem('offer') === 'hg_half_off';

  if (plans.length <= 1) {
    return plans;
  }

  if (hasOffer) {
    // remove subscription and default plans and make promo plan default
    const filteredPlans = plans.filter(plan => plan.type === 'one_off' && !plan.default);
    filteredPlans[0].default = true;
    return filteredPlans;
  }
  // remove promo plan
  return plans.filter(plan => !(plan.type === 'one_off' && !plan.default));
};

const HealthGoalPageComponent: FC<any> = ({ data }) => {
  const { dataPlans, loading: loadingPlans, isAvailable } = useGetProductPlansData(
    data.product.identifier
  );

  const filteredPlans = useMemo(() => {
    if (dataPlans?.product_plans && dataPlans.product_plans.length > 0) {
      return getFilteredPlans(dataPlans.product_plans);
    }
    return [];
  }, [dataPlans]);

  const ingredients = data?.product && data.product.pills[0].nutrients;

  const location = useLocation();

  // tracking
  useEffect(() => {
    trackProductViewed({
      name: data.hero_title,
      url: location.href,
      product_id: data.product?.identifier,
      category: 'health goal',
    });
  }, []);

  return (
    <Layout>
      <SEO title={data.page_title} description={data.page_description} />
      <HeroHealthProduct
        isWithDots={data.bg_with_dots}
        title={data.hero_title}
        subtitle={data.hero_subtitle}
        description={data.hero_description}
        image={data.hero_image}
        backgroundColor={data.hero_background_color}
        loadingPlans={loadingPlans}
        plans={filteredPlans}
      />

      <HowItWorks
        title={data.what_youll_get_title}
        description={data.what_you_get_description}
        items={data.what_you_get_items}
      />

      <Ingredients ingredients={ingredients} />

      <section className={styles.section}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.whyWereDifferentContent}>
              <h2>{data.why_were_different_title}</h2>
              <hr />

              <ComparePanels items={data.why_were_different_reasons} />
            </div>
          </div>
        </div>
      </section>

      <section className={styles.singleMonthlySection}>
        <div className={styles.singleMonthlyImage}>
          <Image
            filename={data.single_monthly_image.filename_disk}
            alt={data.single_monthly_image.title}
            width={1270}
          />
        </div>
        <div className={styles.singleMonthlyContent}>
          <h2 className={styles.h2}>{data.hero_title}</h2>
          <p>
            <strong>{data.hero_subtitle}</strong>
          </p>
          <hr className={styles.hr} />
          {!loadingPlans && (
            <AddToBasket
              plans={filteredPlans}
              isAvailable={isAvailable && filteredPlans.length > 0}
            />
          )}
        </div>
      </section>

      {data?.more_health_goal_items && (
        <RecommendedProducts
          title={data.more_health_goal_title}
          items={data.more_health_goal_items}
        />
      )}
    </Layout>
  );
};

export default HealthGoalPageComponent;
